import React, {useEffect, useState, useRef} from "react";
import {
    IonButton, IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle, IonCol, IonGrid, IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonList, IonRow,
    IonThumbnail
} from "@ionic/react";
import "./challenge-component.styles.scss"
import {useAuth} from "../../auth";
import {firestore, storage} from "../../firebase";
import {toChallenge, toEntry} from "../../models";
import {formatDate} from "../../date";
import {trash as deleteIcon} from "ionicons/icons";

async function savePicture(blobUrl, userId){

    const pictureRef = storage.ref(`/users/${userId}/pictures/${Date.now()}`);

    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const snapshot = await pictureRef.put(blob);
    const url = await snapshot.ref.getDownloadURL();
    console.log('save picture ', url);
    return url;


}

const  ChallengeComponent = ({setPictureUrl, documentDeleting, setDocumentDeleting,
                                 pictureUrl ,challengeTitle, challengeId, challengeWeeklyFreq,challengeDailyFreq, challengeTotal, submissionPayout}
) => {

    const currentDate = new Date().toString().substring(0,15)
    // console.log(currentDate)

    const [ savingPicture, setSavingPicture ] = useState(false)

    const [ entries, setEntries ] = useState([]);

    const { userId } = useAuth();

    const [title, setTitle] = useState('');
    const [ date, setDate ] = useState(new Date().toString())
    const [description, setDescription] = useState('');

    const [ lastClicked, setLastClicked ] = useState(new Date().toString())

    const fileInputRef = useRef();

    useEffect(() => {
        console.log(pictureUrl)

        if ( pictureUrl.startsWith('blob:')) {
            window.URL.revokeObjectURL(pictureUrl);
            console.log('revoked URL: ', pictureUrl);
            setPictureUrl('/assets/placeholder.png')
        }
    },[pictureUrl])

    const handleFileChange = async (event) => {

        if (event.target.files.length > 0) {
            const file = event.target.files.item(0);
            const pictureUrlConst = window.URL.createObjectURL(file);
            console.log('created URL: ', pictureUrlConst)

            await handleSave(pictureUrlConst);
            setPictureUrl(pictureUrlConst)



        }

     fileInputRef.current.click()

    };
    const handleSave = async (pictureUrlConst) => {
        console.log("handleSave")
        const entriesRef = firestore.doc(`users/${userId}/entries/${currentDate.substring(11,15)}/${currentDate}/${challengeTitle}`);
        const entryData = {date, challengeTitle, pictureUrl: pictureUrlConst, lastClicked, description, challengeId};
        if(pictureUrlConst.startsWith('blob:')){
            console.log("saving picture")
            setSavingPicture(true)
            entryData.pictureUrl = await savePicture(pictureUrlConst, userId)
        }
        console.log(entryData)
        const entryRef = await entriesRef.set(entryData);
        const challengeRef = firestore.doc(`users/${userId}/my-challenges/${challengeId}`)

        await challengeRef.get().then((doc) => {
            if (doc.exists){
                console.log(doc.data().totalEarned)
                console.log(doc.data())
                const newTotal = doc.data().totalEarned + 1
                challengeRef.update({totalEarned: newTotal})


            } else {
                console.log("No doc found ")
            }
        }).catch((error) => {
            console.log("Error getting document: ", error);
        })

        // challengeRef.update({lastClicked: lastClicked}).then(r => console.log(r))
        setSavingPicture(false)

        // history.goBack();
    }

    function onDeleteClick(){

        setDocumentDeleting(true)
        const deleteRef = firestore.doc(`users/${userId}/my-challenges/${challengeId}`)

        deleteRef.delete().then(() => {
            console.log("doc deleted: ", challengeId)
            setDocumentDeleting(false)
        }).catch((error) => {
            console.error("error removing doc: ", error)
            setDocumentDeleting(false)

        })
    }

    return (
        <div>
            {savingPicture ? (
                <IonItem>
                    Saving Picture
                </IonItem>
            ) : (
                <IonCard
                    // onClick={() => handleSave()}
                    // routerLink={`/my/entries/view/${challengeId}/${entry.date.substring(16,24)}`}
                    // routerLink="/my/entries/add"
                    className="challenge-component-card">
                    <IonGrid>
                        <IonRow>

                            <div>

                                <div className="challenge-title-div" >
                                    {challengeTitle}
                                </div>



                                <div className="add-submission-picture-div">

                                    <input type="file" accept="image/*" hidden ref={fileInputRef}
                                           onChange={handleFileChange}
                                    />
                                </div>


                            </div>



                                <div className="challenge-thumbnail-div">
                                    {/*<IonButton style={{cursor: "pointer"}} onClick={() => fileInputRef.current.click()} >Submit</IonButton>*/}
                                    <img style={{cursor: "pointer"}} onClick={() => window.location.href = `/my/challenges/add-entry/${challengeId}/${challengeTitle}`} src={pictureUrl} alt=""/>
                                    {/*<img style={{cursor: "pointer"}} onClick={() => fileInputRef.current.click()} src={pictureUrl} alt=""/>*/}

                                </div>






                            <div className="trash-icon-container-div">
                                <IonIcon style={{width: "1.5em", height: "1.5em", marginTop: "auto",
                                }} icon={deleteIcon} onClick={() => onDeleteClick()}/>

                            </div>



                        </IonRow>








                    </IonGrid>
                </IonCard>
            )}


        </div>

    )
}
export default ChallengeComponent;
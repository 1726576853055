import React, {useContext, useEffect, useState} from 'react';
import {auth as firebaseAuth, firestore} from "./firebase";




interface Auth {
    loggedIn: boolean;
    userId? : string;
    totalCurrency?: number;
}
interface AuthInit {
    loading: boolean;
    auth?: Auth;
    totalCurrency?: number;
}

export const AuthContext = React.createContext<Auth>({ loggedIn: false, totalCurrency: 0})

export function useAuth(): Auth{
    return useContext(AuthContext);
}

export function useAuthInit(): AuthInit {
    const [authInit, setAuthInit ] = useState<AuthInit>({loading: true})
    const [ total, setTotal] = useState(0);
    useEffect(() => {

        return firebaseAuth.onAuthStateChanged((firebaseUser) => {

            if (firebaseUser !== undefined) {
                const myChallengesRef = firestore.collection('users')
                    .doc(firebaseUser?.uid)
                    .collection('my-challenges');

                myChallengesRef.orderBy('startDate', 'asc').limit(30)
                    .onSnapshot( ({docs}) => {
                        let totalVar = 0;

                        docs.map(doc => {
                            totalVar = totalVar + doc.data().totalEarned;
                            console.log(doc.data().totalEarned)
                        })
                        console.log(totalVar)
                        setTotal(totalVar)

                        const auth = firebaseUser ?
                            ( {loggedIn: true, userId: firebaseUser.uid, totalCurrency: totalVar} ):
                            ( {loggedIn: false });

                        console.log(totalVar)
                        setAuthInit({ loading: false, auth,  })
                        console.log(totalVar)
                    })
            }




        })

    },[]);
    console.log(authInit)
    return authInit;
}
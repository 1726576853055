import {
    IonButtons,
    IonContent,
    IonHeader, IonPage, IonBackButton,
    IonTitle,
    IonToolbar, IonLabel, IonInput, IonList, IonItem, IonButton, IonDatetime, IonSelect, IonSelectOption,
} from '@ionic/react';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from "../auth";
import { useHistory} from "react-router-dom";
import {firestore, storage} from "../firebase";
import myChallengesPage from "./MyChallengesPage";


async function savePicture(blobUrl, userId){

    const pictureRef = storage.ref(`/users/${userId}/pictures/${Date.now()}`);

    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const snapshot = await pictureRef.put(blob);
    const url = await snapshot.ref.getDownloadURL();
    console.log('save picture ', url);
    return url;


}

const AddChallengePageJs  = () => {
    const { userId } = useAuth()
    const history = useHistory();



    const [title, setTitle] = useState('');
    const [ date, setDate ] = useState(new Date().toString())
    const [description, setDescription] = useState('');

    const [ challengeWeeklyFreq, setChallengeWeeklyFrequency ] = useState(0);
    const [ challengeDailyFreq, setChallengeDailyFrequency ] = useState(0);
    const [ submissionPayout, setSubmissionPayout ] = useState(0);
    const [ submissionType, setSubmissionType ] = useState('');
    const [ challengeTotal, setChallengeTotal ] = useState(0);

    const [pictureUrl, setPictureUrl ] = useState('/assets/placeholder.png')

    const fileInputRef = useRef();

    const [ savingPicture, setSavingPicture ] = useState(false)


    let currentDate = new Date();
    let monthDate = new Date();
    monthDate.setHours(730)
    console.log(currentDate.toDateString(), " - ", monthDate.toDateString())

    const startDate = currentDate.toDateString();
    const endDate = monthDate.toDateString();
    let challengeTotalVar;

    function renderTotal(){
        const challengeTotalVar = challengeWeeklyFreq * challengeDailyFreq * 4 * submissionPayout;
        setChallengeTotal(challengeTotalVar);
        console.log(challengeTotalVar);

    }


    useEffect(() => {



        console.log(userId)
    }, [userId])

    const handleFileChange = async (event) => {

        if (event.target.files.length > 0) {
            const file = event.target.files.item(0);
            const pictureUrlConst = window.URL.createObjectURL(file);
            console.log('created URL: ', pictureUrlConst)

            // await handleSave(pictureUrlConst);
            setPictureUrl(pictureUrlConst)



        }

    };
    const handleSave = async ({challengeId}) => {
        console.log(pictureUrl)
        console.log("handleSave")
        const entriesRef = firestore.doc(`users/${userId}/entries/${currentDate.toString().substring(11,15)}/${currentDate.toString().substring(0,15)}/${title}`);
        const entryData = {date, challengeTitle: title, pictureUrl: pictureUrl, lastClicked: currentDate.toString(), description, challengeId };
        if(pictureUrl.startsWith('blob:')){
            console.log("saving picture")
            setSavingPicture(true)
            entryData.pictureUrl = await savePicture(pictureUrl, userId)
        }
        console.log(entryData)
        const entryRef = await entriesRef.set(entryData);
        // const challengeRef = firestore.doc(`users/${userId}/my-challenges/${challengeId}`)
        //
        // await challengeRef.get().then((doc) => {
        //     if (doc.exists){
        //         console.log(doc.data().totalEarned)
        //         console.log(doc.data())
        //         const newTotal = doc.data().totalEarned + 1
        //         challengeRef.update({totalEarned: newTotal})
        //
        //
        //     } else {
        //         console.log("No doc found ")
        //     }
        // }).catch((error) => {
        //     console.log("Error getting document: ", error);
        // })

        // challengeRef.update({lastClicked: lastClicked}).then(r => console.log(r))
        // setSavingPicture(false)

        // history.goBack();
    }

    const createChallenge = async () => {
        const myChallengesRef = firestore.collection('users').doc(userId)
            .collection('my-challenges');
        const challengeData = {
             title,
             description,
            pictureUrl,


        };



        const challengeRef = await myChallengesRef.add(challengeData)
            .then(async (docRef) => {
                console.log('Challenge Created', docRef.id);
                myChallengesRef.doc(docRef.id).set({challengeId: docRef.id, ...challengeData})
                // history.goBack();
                await handleSave({challengeId: docRef.id});

                window.location.href = "/my/challenges";


            });

    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>Create Challenge</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonList >

                    <IonItem>
                        <IonLabel  >Challenge Title: </IonLabel>
                        <IonInput
                            required={true}
                            value={title}
                            onIonChange={(event) => setTitle(event.detail.value)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Picture</IonLabel><br />
                        <input type="file" accept="image/*" hidden ref={fileInputRef}
                               onChange={handleFileChange}
                        />
                        <img style={{cursor: "pointer"}} onClick={() => fileInputRef.current.click()} src={pictureUrl} alt=""/>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Description</IonLabel>
                        <IonInput value={description}
                                  onIonChange={(event) => setDescription(event.detail.value)}
                        />
                    </IonItem>


                    {/*<IonItem>*/}
                    {/*    <IonLabel> Weekly Frequency</IonLabel>*/}
                    {/*    <IonSelect value={challengeWeeklyFreq} placeholder="0"*/}
                    {/*               onIonChange={(e) => setChallengeWeeklyFrequency(e.detail.value)}*/}
                    {/*               interface="popover">*/}
                    {/*        <IonSelectOption>1</IonSelectOption>*/}
                    {/*        <IonSelectOption>2</IonSelectOption>*/}
                    {/*        <IonSelectOption>3</IonSelectOption>*/}
                    {/*        <IonSelectOption>4</IonSelectOption>*/}
                    {/*        <IonSelectOption>5</IonSelectOption>*/}
                    {/*        <IonSelectOption>6</IonSelectOption>*/}
                    {/*        <IonSelectOption>7</IonSelectOption>*/}
                    {/*    </IonSelect>*/}
                    {/*</IonItem>*/}
                    {/*<IonItem>*/}
                    {/*        <IonLabel> Daily Frequency</IonLabel>*/}
                    {/*        <IonSelect value={challengeDailyFreq} placeholder="0"*/}
                    {/*                   onIonChange={(e) => setChallengeDailyFrequency(e.detail.value)}*/}
                    {/*                   interface="popover">*/}
                    {/*            <IonSelectOption>1</IonSelectOption>*/}
                    {/*            <IonSelectOption>2</IonSelectOption>*/}
                    {/*            <IonSelectOption>3</IonSelectOption>*/}
                    {/*            <IonSelectOption>4</IonSelectOption>*/}
                    {/*            <IonSelectOption>5</IonSelectOption>*/}
                    {/*        </IonSelect>*/}
                    {/*    </IonItem>*/}

                    {/*              <IonItem>*/}
                    {/*                  <IonLabel>Submission Payout </IonLabel>*/}
                    {/*                  <span>$</span>*/}
                    {/*                  <IonSelect value={submissionPayout} placeholder="0"*/}
                    {/*                             onIonChange={(e) => setSubmissionPayout(e.detail.value)}*/}
                    {/*                             interface="popover">*/}
                    {/*s                      <IonSelectOption>9</IonSelectOption>*/}
                    {/*                      <IonSelectOption>27</IonSelectOption>*/}
                    {/*                      <IonSelectOption>81</IonSelectOption>*/}
                    {/*                  </IonSelect>*/}
                    {/*              </IonItem>*/}
                    {/*<IonItem>*/}
                    {/*    <IonLabel>Submission Type </IonLabel>*/}
                    {/*    <IonSelect value={submissionType} placeholder=""*/}
                    {/*               onIonChange={(e) => setSubmissionType(e.detail.value)}*/}
                    {/*               interface="popover">*/}
                    {/*        <IonSelectOption>Image</IonSelectOption>*/}
                    {/*        <IonSelectOption>Time</IonSelectOption>*/}
                    {/*        <IonSelectOption>Reps</IonSelectOption>*/}
                    {/*    </IonSelect>*/}
                    {/*</IonItem>*/}

                    {/*<IonItem>*/}
                    {/*    <IonLabel>Total: </IonLabel>*/}

                    {/*        {challengeTotal !== 0 &&(*/}
                    {/*        <IonLabel>${challengeTotal}</IonLabel>*/}
                    {/*            )}*/}


                    {/*</IonItem>*/}

                    {/*<IonItem>*/}
                    {/*    <IonLabel>Challenge Dates</IonLabel>*/}
                    {/*</IonItem>*/}


                    {/*<IonItem>*/}
                    {/*        <IonLabel className="ion-padding">{startDate} - {endDate}</IonLabel>*/}

                    {/*    </IonItem>*/}

                    <IonButton expand="block" onClick={createChallenge}>Join Challenge</IonButton>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default AddChallengePageJs;

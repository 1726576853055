import firebase from 'firebase';
export const initializeFirebase = () => {
    firebase.initializeApp({
        apiKey: "AIzaSyC1ERMXlKEiLY-2WrJkXpLDG4mHha0iNYI",
        authDomain: "daily-moments-fe801.firebaseapp.com",
        projectId: "daily-moments-fe801",
        storageBucket: "daily-moments-fe801.appspot.com",
        messagingSenderId: "1036578516348",
        appId: "1:1036578516348:web:db2fbe69741c7621c448f9"
    });
}
export const askForPermissionToReceiveNotifications = async () => {
    try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();
        console.log('Your token is:', token);

        return token;
    } catch (error) {
        console.error(error);
    }
}
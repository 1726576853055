import {
    IonButton,
    IonContent,
    IonHeader, IonPage, IonRouterLink,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React from 'react';
import { auth} from "../firebase";

const SettingsPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
          <IonButton
              onClick={() => auth.signOut()}
              color="medium" expand="block">Logout</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;

import React, {useEffect, useRef, useState} from "react"
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonItem, IonList} from "@ionic/react";
import {firestore} from "../../firebase";
import {useAuth} from "../../auth";
import {toChallenge} from "../../models";
import {formatSubmissionsTimes} from "../../date";
import "./submissions-component.styles.scss"
import {eyeOutline, eyeOffOutline} from "ionicons/icons"

const SubmissionsComponent = () => {

    const { userId } = useAuth();
    const currentDate = new Date().toString().substring(0,15)

    const [ submissions, setSubmissions ] = useState([])
    const [showPictures, setShowPictures] = useState(true)

    const videoRef = useRef(null);




    useEffect(async () => {
        // let tempSub = [];

        const mySubmissionsRef = firestore.collection('users')
            .doc(userId)
            .collection('entries')
            .doc(currentDate.substring(11, 15))
            .collection(currentDate)

        await mySubmissionsRef.orderBy('lastClicked', 'desc').limit(20)
            .onSnapshot(({docs}) => {
                setSubmissions(docs.map(toChallenge))
                // tempSub = docs.map(toChallenge);

                docs.map(doc => {
                    console.log(doc.data())

                })
                // console.log(tempSub, submissions)
                // if (tempSub.length > 0 ){
                //     setUpVideo(tempSub);
                //
                // }
            })



    },[])


    // function setUpVideo(videoSubArr){
    //     console.log(videoSubArr)
    //
    //     videoSubArr.map(x =>{
    //         console.log(x.videoUrl)
    //         videoRef.current.srcObject = x.videoUrl;
    //     })
    //      // submissions.map((videoSubmission) => {
    //      //     console.log(videoSubmission.videoUrl)
    //      // })
    // }
    return (
        <IonCard className="submission-component-card">

            {submissions.length > 0 && (
                <IonCardHeader style={{padding: 0}}>
                    <IonItem lines="none">
                        <IonCardTitle style={{width: "100%", textAlign: "center"}}>
                            <div>
                                {currentDate.substring(0,10)}

                            </div>


                        </IonCardTitle>
                        {showPictures ? (
                            <span onClick={() => setShowPictures(!showPictures)} style={{ cursor: "pointer", width: "fit-content"}}>
                            <IonIcon icon={eyeOutline}/>
                        </span>
                        ):(
                            <span onClick={() => setShowPictures(!showPictures)} style={{cursor: "pointer", width: "fit-content"}}>
                            <IonIcon icon={eyeOffOutline}/>
                        </span>
                        )}



                    </IonItem>

                </IonCardHeader>

            )}


                {submissions.map(sub => (
                        <div key={sub?.challengeId} className="submission-component-div">
                            <IonList>
                                <div className="submission-component-challenge-title">{sub?.challengeTitle}</div>

                                <div>{sub?.date.toString().substring(16,21)}</div>
                                {showPictures && sub?.pictureUrl !== '/assets/placeholder.png' &&  sub?.pictureUrl &&(
                                    <div className="submission-thumbnail-div">
                                        < img src={sub?.pictureUrl}/>

                                    </div>

                                )}
                                {sub?.videoUrl && (


                                        <div className="video-container-div">
                                            <video className="video-insert"  src={sub?.videoUrl} controls loop playsInline autoPlay />
                                        </div>


                                )}
                                <div>{sub?.description}</div>

                            </IonList>

                        </div>
                    )
                )}

        </IonCard>
    )
}

export default SubmissionsComponent;
import React, {useEffect, useRef, useState} from "react"
import {IonButton, IonGrid, IonIcon, IonItem, IonList, IonPage, IonRow} from "@ionic/react";
import {chevronUpCircle, videocam as camera, stopCircleOutline as cameraOff} from "ionicons/icons";
import PlayVideo from "./play-video";
import "./play-video.styles.scss"


const AddVideo =() => {

const captureRef = useRef()
    const mediaObject = useRef();

    const [ chunksState, setChunksState ] = useState([]);
    const [ captured, setCaptured ] =useState(undefined);

    const [ mediaRecorderState, setMediaRecorderState ] = useState(undefined)

    const [ stopRecordingBool, setStopRecordingBool ] = useState(false)
    const [ isRecording, setIsRecording ] = useState(false);

    const [mediaStreamState, setMediaStreamState] = useState(undefined)

    const [stream, setStream ] = useState()
    let stream1;
    let chunks= [];



    async function setMediaRecorder() {

        stream1 = await navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: "user"
            },
            audio: true
        });

        console.log(stream1)

        // captureRef.current.srcObject = stream1;
          setMediaStreamState(stream1)

        const options = {mimeType: "video/webm"};
        let mediaRecorder = new MediaRecorder(stream1, options);

        setMediaRecorderState(Object.assign(mediaRecorder))

    }


    useEffect(async () => {
        // console.log(isRecording, "<= isRecording")


        setMediaRecorder()
        console.log("useEffect record: ", isRecording)

        console.log(mediaRecorderState)


    },[])

    console.log(mediaRecorderState)


    function stopRecording(){
        console.log(mediaRecorderState)
        setIsRecording(false)


        if (mediaRecorderState.state === "recording") {

            console.log("RECORDING")
            // mediaRecorder.requestData();
            mediaRecorderState.stop();



            mediaRecorderState.ondataavailable = (event) => {
                console.log("DATA AVAILABLE", event)
                if (event.data && event.data.size > 0){
                    console.log("Pushing to chunk")

                    chunks.push(event.data)
                }
            }
            mediaRecorderState.onstop = async (event) => {
                console.log("ONStop")
                console.log(chunks)

                const videoBuffer = new Blob(chunks, { type: 'video/webm'});
                console.log(videoBuffer)
                setCaptured(videoBuffer);

            }


            // console.log(mediaRecorderState)
            // setMediaRecorderState(undefined)
        }
        // setIsRecording(false)


        // captureElement.current.srcObject = null;



    }

    async function recordVideo() {
        mediaRecorderState.start()
        setIsRecording(true)
        console.log("In record video")
        // setStopRecordingBool(false)


    }


    function playVideo(){

    }

    return (
        <IonPage>
            <IonList style={{scrollY: "auto"}}>
                {/*<div hidden style={{marginBottom: "5em"}}>*/}
                {/*    <video ref={captureRef}  className="video" autoPlay playsInline muted></video>*/}

                {/*</div>*/}



                <PlayVideo
                    preview={mediaStreamState}
                    captured={captured}
                    isRecording={isRecording}
                    setCaptured={setCaptured}

                />
                {captured === undefined &&(
                    <IonGrid>
                        <IonRow>
                            <div className="record-video-button">
                                <IonButton color={isRecording ? "danger" : "primary"}  onClick={() => isRecording ? stopRecording() : recordVideo()}>
                                    <IonIcon icon={isRecording ? cameraOff : camera}/>
                                </IonButton>
                            </div>

                        </IonRow>


                    </IonGrid>
                )}


            </IonList>

        </IonPage>
    )
}

export default AddVideo;
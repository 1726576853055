import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonBackButton,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonInput,
    IonList,
    IonItem,
    IonButton,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonGrid, IonRow, IonCol,
} from '@ionic/react';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from "../auth";
import { useHistory} from "react-router-dom";
import {firestore, storage} from "../firebase";
import myChallengesPage from "./MyChallengesPage";
import {useParams} from "react-router";
import {cameraOutline, stopCircleOutline as cameraOff, videocam as camera} from "ionicons/icons";
import AddVideo from "./video/AddVideo";
import UseVideo from "../components/use-video/use-video";
import "./AddChallengeEntry.styles.scss"

async function saveVideo(blobUrl, userId){
    console.log(blobUrl, userId)


    const videoRef = storage.ref(`/users/${userId}/videos/${Date.now()}`);

    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const snapshot = await videoRef.put(blob);
    const url = await snapshot.ref.getDownloadURL();
    console.log('save video ', url);
    return url;


}

async function savePicture(blobUrl, userId){

    const pictureRef = storage.ref(`/users/${userId}/pictures/${Date.now()}`);

    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const snapshot = await pictureRef.put(blob);
    const url = await snapshot.ref.getDownloadURL();
    console.log('save picture ', url);
    return url;


}

const AddChallengeEntry  = () => {
    const { userId } = useAuth()
    const history = useHistory();

    const [firebaseUrl, setFirebaseUrl] = useState(undefined);



    const [title, setTitle] = useState('');
    const [ date, setDate ] = useState(new Date().toString())
    const [description, setDescription] = useState('');
    const [addSubmissionButton, setAddSubmissionButton ] = useState(false)

    const [ usingCamera, setUsingCamera ] = useState(false)
    const [ challengeWeeklyFreq, setChallengeWeeklyFrequency ] = useState(0);
    const [ challengeDailyFreq, setChallengeDailyFrequency ] = useState(0);
    const [ submissionPayout, setSubmissionPayout ] = useState(0);
    const [ submissionType, setSubmissionType ] = useState('');
    const [ challengeTotal, setChallengeTotal ] = useState(0);

    const [pictureUrl, setPictureUrl ] = useState(undefined)
    const [videoUrl, setVideoUrl ] = useState(undefined)
    // const [pictureUrl, setPictureUrl ] = useState('/assets/placeholder.png')

    const [ addingSubmission, setAddingSubmission ] = useState(false)
    const fileInputRef = useRef();
    const imageInputRef = useRef();
    const videoInputRef = useRef();

    const [ savingPicture, setSavingPicture ] = useState(false)


    const params = useParams();

    let currentDate = new Date();
    let monthDate = new Date();
    monthDate.setHours(730)
    console.log(currentDate.toDateString(), " - ", monthDate.toDateString())


    console.log(params)

    useEffect(() => {
        if ((videoUrl || pictureUrl )&& description){
            console.log(videoUrl, pictureUrl, description)

            setAddSubmissionButton(true)
        }

    },[videoUrl,pictureUrl, description])



    const handleFileChangeImage = async (event) => {

        if (event.target.files.length > 0) {
            const file = event.target.files.item(0);
            const pictureUrlConst = window.URL.createObjectURL(file);
            console.log('created URL: ', pictureUrlConst)

            // await handleSave(pictureUrlConst);
            setPictureUrl(pictureUrlConst)



        }

    };
    const handleFileChangeVideo = async (event) => {

        if (event.target.files.length > 0) {
            const file = event.target.files.item(0);
            const videoUrlConst = window.URL.createObjectURL(file);
            console.log('created URL: ', videoUrlConst)

            // await handleSave(pictureUrlConst);
            setVideoUrl(videoUrlConst)



        }

    };
    const handleSave = async () => {
        const{ id, id2 } = params;

        if ( description !== ''){
            if (pictureUrl){
                console.log("Saving picture",pictureUrl)
                const entriesRef = firestore.doc(`users/${userId}/entries/${currentDate.toString().substring(11,15)}/${currentDate.toString().substring(0,15)}/${id2}`);
                const entryData = {date, challengeTitle: id2, pictureUrl: pictureUrl, lastClicked: currentDate.toString(), description, challengeId: id, videoUrl: '' };
                if(pictureUrl.startsWith('blob:')){
                    console.log("saving picture")
                    setAddingSubmission(true)
                    entryData.pictureUrl = await savePicture(pictureUrl, userId)
                }
                console.log(entryData)
                await entriesRef.set(entryData);
            } else if (videoUrl){
                console.log("Saving video",videoUrl)
                const entriesRef = firestore.doc(`users/${userId}/entries/${currentDate.toString().substring(11,15)}/${currentDate.toString().substring(0,15)}/${id2}`);
                const entryData = {date, challengeTitle: id2, pictureUrl: '', lastClicked: currentDate.toString(), description, challengeId: id, videoUrl: firebaseUrl };


                if (videoUrl.startsWith('blob:')){
                    setAddingSubmission(true)
                    entryData.videoUrl = await saveVideo(videoUrl, userId)
                }
                console.log(videoUrl,entryData.videoUrl)
                setFirebaseUrl(entryData.videoUrl)
                await entriesRef.set(entryData);

            }


        }else {
            console.log("NO DESCIRPTION")

        }



    }

    function createdChallenge(){
        setAddingSubmission(false)
        window.location.href = "/my/challenges"
    }

    const createChallenge = async () => {
                // await handleSave().then(() => window.location.href = "/my/challenges");
                await handleSave().then(() => createdChallenge())
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>Challenge Submission</IonTitle>
                </IonToolbar>
            </IonHeader>
            {addingSubmission ? (<div>Adding Submission</div>):(
                <IonContent className="ion-padding">
                    <IonList >

                        <IonItem>
                            <div  >{params.id2}</div>
                            <IonInput
                                required={true}
                                value={title}
                                onIonChange={(event) => setTitle(event.detail.value)}
                            />
                        </IonItem>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton onClick={() => videoInputRef.current.click()}  style={{magrin: "auto"}}>
                                        Video
                                        <IonIcon style={{paddingLeft: ".4em"}} icon={camera}/>
                                    </IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={() => imageInputRef.current.click()}  style={{magrin: "auto"}} >
                                        Picture
                                        <IonIcon style={{paddingLeft: ".4em"}} icon={cameraOutline}/>
                                    </IonButton>
                                </IonCol>


                            </IonRow>


                        </IonGrid>
                        {/*<div style={{backgroundColor: "green" ,height: "fit-content"}}>*/}
                        {/*    <UseVideo*/}
                        {/*        challengeTitle={params.id2}*/}
                        {/*        challengeId={params.id}*/}
                        {/*        firebaseUrl={firebaseUrl}*/}
                        {/*        setFirebaseUrl={setFirebaseUrl}*/}

                        {/*    />*/}
                        {/*</div>*/}


                        <IonItem>
                            <input type="file" accept="image/*" hidden ref={imageInputRef}
                                   onChange={handleFileChangeImage}
                            />
                            <input type="file" accept="video/mp4,video/x-m4v,video/*" hidden ref={videoInputRef}
                                   onChange={handleFileChangeVideo}
                            />
                            {pictureUrl && (
                                <div style={{width: "80%", margin: "auto", backgroundColor: "green"}}>
                                    {/*<img style={{cursor: "pointer"}} onClick={() => fileInputRef.current.click()} src={pictureUrl} alt=""/>*/}
                                    <img style={{cursor: "pointer"}}  src={pictureUrl} alt=""/>


                                </div>
                            )}
                            {videoUrl && (
                                <div className="uploaded-video-container-div">
                                    {/*<img style={{cursor: "pointer"}} onClick={() => fileInputRef.current.click()} src={pictureUrl} alt=""/>*/}
                                    <video className="uploaded-challenge-entry-video" style={{cursor: "pointer"}}  src={videoUrl} loop playsInline autoPlay/>


                                </div>
                            )}

                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">Description</IonLabel>
                            <IonInput value={description}
                                      onIonChange={(event) => setDescription(event.detail.value)}
                            />
                        </IonItem>


                        <IonButton disabled={!addSubmissionButton} expand="block" onClick={createChallenge}>Add Submission</IonButton>
                    </IonList>
                </IonContent>
            )}

        </IonPage>
    );
};

export default AddChallengeEntry;

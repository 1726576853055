import React, {useEffect, useRef, useState} from "react";
import "./play-video.styles.scss"
import {IonButton, IonItem} from "@ionic/react";
import {firestore, storage} from "../../firebase";
import {useAuth} from "../../auth";


async function saveVideo(blobUrl, userId){


    const videoRef = storage.ref(`/users/${userId}/videos/${Date.now()}`);

    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const snapshot = await videoRef.put(blob);
    const url = await snapshot.ref.getDownloadURL();
    console.log('save picture ', url);
    return url;


}


const PlayVideo = ({ isRecording, preview, captured, setCaptured}, ) => {
    let currentDate = new Date();
    const [savingVideo, setSavingVideo ] = useState(false)
    const [videoSaved, setVideoSaved ] =  useState(false)

    const [title, setTitle ] = useState("Name of challenge")
    const { userId } = useAuth()

    const [videoUrl, setVideoUrl] = useState(undefined);
    const [firebaseUrl, setFirebaseUrl] = useState(undefined);

    console.log(preview, captured, isRecording)
    const previewRef = useRef(null);
    const capturedRef = useRef(null);

    function setupPreview(){
        previewRef.current.srcObject = preview;
        console.log("Preview setup")
    };
    function setupCaptured(){
      const url = captured && window.URL.createObjectURL(captured);
      capturedRef.current.src = url;
      console.log(captured, capturedRef, url)
        setVideoUrl(url)
        console.log("Captured setup")

    };
    useEffect(() => {
        setupPreview();
        setupCaptured();

    },[isRecording, preview, captured])



    const handleSave = async () => {
        setSavingVideo(true)
        const entriesRef = firestore.doc(`users/${userId}/entries/${currentDate.toString().substring(11,15)}/${currentDate.toString().substring(0,15)}/${title}`);

        const entryData = {videoUrl, lastClicked: currentDate.toString(), date: currentDate.toString()};

        if (videoUrl.startsWith('blob:')){
            entryData.videoUrl = await saveVideo(videoUrl, userId)
        }
        setFirebaseUrl(entryData.videoUrl)

        await entriesRef.set(entryData).then(() =>(
            setSavingVideo(false),
            setVideoSaved(true)

        ) )
    }
    function handleLinkButtonClick(){
        window.location.href= firebaseUrl.toString();
    }
    function onClickX(){
        setCaptured(undefined);
        setVideoSaved(false);
    }

    return(
        <div>


            <div>
                {captured !== undefined ? (

                    <div className="play-video-container-div">
                        <div hidden ref={previewRef}> </div>

                        <div onClick={() => onClickX()} className="close-icon-x">x</div>
                        <div>
                            <video className="video-captured" ref={capturedRef} loop playsInline autoPlay/>

                        </div>

                    </div>

                ):(
                    <div className="play-video-container-div">
                        <div hidden ref={capturedRef}> </div>

                        <div>
                            <video className="video-preview" ref={previewRef} autoPlay playsInline muted />
                        </div>
                    </div>

                )
                }

            </div>
            <div >
                {captured && !savingVideo &&(
                    <IonButton onClick={() => handleSave()}>Save Captured Video</IonButton>

                )}
                {savingVideo && (
                    <h1>Saving videos</h1>
                )}
                {videoSaved && (
                    <IonButton  onClick={() => handleLinkButtonClick()}>{firebaseUrl.toString()}</IonButton>
                )}
            </div>

        </div>
    )

}
export default PlayVideo;
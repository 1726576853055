import {
    IonContent, IonImg, IonThumbnail, IonFab, IonFabButton,
    IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRouterLink,
    IonTitle,
    IonToolbar, IonButton,
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import { firestore } from "../firebase";
import { Challenge, toChallenge} from "../models";
import {useAuth} from "../auth";
import {add as addIcon, options} from 'ionicons/icons'
import Profile from "../components/profile/profile";
import SubmissionsComponent from "../components/submissions-component/submissions-component";
import {askForPermissionToReceiveNotifications} from "../push-notification";
import {scheduleNotification} from "../local-notifictions/notifications";



const HomePage: React.FC = () => {

    const { userId } = useAuth();

    useEffect(() => {
        askForPermissionToReceiveNotifications().then(x => console.log(x))


    }, [userId])


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Home</IonTitle>
        </IonToolbar>
      </IonHeader>
        <Profile />

        <IonContent className="ion-padding">


          <SubmissionsComponent
          />
            {/*<IonButton color="tertiary" onClick={() => scheduleNotification()}>*/}
            {/*    Schedule Notification*/}
            {/*</IonButton>*/}
          {/*<IonFab  vertical="bottom" horizontal="end">*/}
          {/*    /!*<IonFabButton routerLink="/my/entries/add">*!/*/}
          {/*    /!*    <IonIcon icon={addIcon}/>*!/*/}
          {/*    /!*</IonFabButton>*!/*/}
          {/*</IonFab>*/}
      </IonContent>
    </IonPage>
  );
};

export default HomePage;

import React from "react";

import Clock from 'react-live-clock';
import "./live-clock.styles.scss"


const LiveClock = () => {


    const currentDate =  new Date();
    currentDate.setHours(0)
    const currentIsoDate = currentDate.toDateString();
    console.log(currentDate, currentIsoDate)
    return (
        <div className="live-clock-container">
            <p>{currentIsoDate}</p>
            <Clock
                format={' h:mm:ss a'}
                style={{fontSize: '1.5em'}}
                ticking={true} />
        </div>

    )
}



export default LiveClock;
import React, {useEffect, useState} from "react";
import {IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonPage} from "@ionic/react";
import {firestore} from "../../firebase";
import {Challenge, toChallenge, toSubmission, toUser, User} from "../../models";
import {useAuth} from "../../auth";

const Profile = () => {
    const { userId, totalCurrency } = useAuth();
    const [ challenges, setChallenges ] = useState<Challenge[]>([]);
    // const [ currentUser, setCurrentUser] = useState<User>();


    useEffect(() => {



    },[])
    // console.log(currentUser)
    return(
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>Hector Amador</IonCardTitle>
                    <IonCardSubtitle>$ {totalCurrency}</IonCardSubtitle>
                </IonCardHeader>
            </IonCard>


    )
}

export default Profile;
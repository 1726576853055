import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonBackButton,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonInput,
    IonList,
    IonItem,
    IonButton,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonFabButton,
    IonIcon, IonFab, IonThumbnail, IonImg,
} from '@ionic/react';
import "./MyChallengesPage.styles.scss"
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from "../auth";
import { useHistory} from "react-router-dom";
import {add as addIcon, flagOutline, camera as cameraIcon, informationCircle as infoIcon} from "ionicons/icons";
import {formatDate} from "../date";
import {firestore} from "../firebase";
import {Challenge, Submission, toChallenge, toEntry, toSubmission} from "../models";
import ChallengeComponent from "../components/challenge-component/challenge-component";
import LiveClock from "../components/live-clock/live-clock";




const MyChallengesPage: React.FC = () => {
    const { userId } = useAuth()
    const history = useHistory();

    const [ challenges, setChallenges ] = useState<Challenge[]>([]);
    const [ submissions, setSubmissions ] = useState<Submission[]>([]);

    const fileInputRef = useRef<HTMLInputElement>();

    const [total, setTotal ] = useState(0)
    const [ entries, setEntries ] = useState([]);
    const [ pictureUrl, setPictureUrl ] = useState('/assets/placeholder.png');

    const [ documentDeleting, setDocumentDeleting] = useState(false)

    const [challengeBeingAdded, setChallengeBeingAdded ] = useState(false)
    const currentDate = new Date().toString().substring(0,15)

    let challengesArray= [];
    let challengesArrayId = [];
    let submissionsArrayId = [];
    useEffect(() => {

        if (userId !== undefined){
            const mySubmissionsRef = firestore.collection('users')
                .doc(userId)
                .collection('entries')
                .doc(currentDate.substring(11,15))
                .collection(currentDate)

             mySubmissionsRef.orderBy('date', 'asc').limit(30)
                .onSnapshot( ({docs}) => {
                    setSubmissions(docs.map(toSubmission))
                    docs.map(doc => {
                        console.log(doc.data().challengeTitle)
                        submissionsArrayId = [...submissionsArrayId, doc.data().challengeId]

                    })

                    const myChallengesRef = firestore.collection('users').doc(userId)
                        .collection('my-challenges');
                    myChallengesRef.orderBy('startDate', 'asc').limit(30)
                        .onSnapshot(({ docs }) => {

                            let totalVar = 0;
                            docs.map(doc => {
                                totalVar = totalVar + doc.data().challengeTotal;
                                // console.log(doc.data().challengeTitle)
                                //
                                // console.log(submissionsArrayId)
                                // console.log(challengesArray)
                                console.log(doc.data())

                                let challengeAlreadyInArray = challengesArrayId.includes(doc.data().challengeId)

                                let challengeAlreadySubmitted = submissionsArrayId.includes( doc.data().challengeId)
                                if ( challengeAlreadySubmitted === false && challengeAlreadyInArray === false){

                                    challengesArray = [...challengesArray, doc.data()]
                                    challengesArrayId = [...challengesArrayId, doc.data().challengeId]
                                    // console.log(challengesArray)
                                    // console.log(challengesArrayId)


                                }else {
                                    // console.log("challengeAlreadySubmitted",challengeAlreadySubmitted)
                                    // console.log(challengesArray)
                                }



                            })
                            setTotal(totalVar)

                            console.log(challengesArray)
                            setChallenges(challengesArray)




                        })


                })


            console.log(submissionsArrayId)
            console.log("adding challenge", challengeBeingAdded)


        }



    }, [userId, pictureUrl, documentDeleting, challengeBeingAdded ])


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        console.log("HandleFile Change")
        if ( event.target.files.length > 0) {
            const file = event.target.files.item(0);
            const pictureUrl = URL.createObjectURL(file);
            console.log('created URL: ', pictureUrl)
            setPictureUrl(pictureUrl)
        }


    };

    return (
        <IonPage >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>Challenges</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent >
                <IonItem>
                    <LiveClock />
                </IonItem>
                    <IonList className="my-challenges-list"  >
                        {/*<div>${total}</div>*/}


                        {documentDeleting ? (
                            <div>Document Deleting</div>
                        ): (
                            <div>
                                {challenges.map((challenge) =>


                                    <ChallengeComponent key={challenge.challengeId}
                                                        challengeTitle={challenge.challengeTitle}
                                                        submissionPayout={challenge.submissionPayout}
                                                        challengeWeeklyFreq={challenge.challengeWeeklyFreq}
                                                        challengeDailyFreq={challenge.challengeDailyFreq}
                                                        challengeTotal={challenge.challengeTotal}
                                                        challengeId={challenge.challengeId}
                                                        pictureUrl={pictureUrl}
                                                        setPictureUrl={setPictureUrl}
                                                        documentDeleting={documentDeleting}
                                                        setDocumentDeleting={setDocumentDeleting}

                                    />
                                )}
                            </div>


                            )}








                    </IonList>

                {/*<IonFab  vertical="bottom" horizontal="center">*/}
                {/*    <IonFabButton routerLink="/my/entries/add">*/}
                {/*        <IonIcon icon={addIcon}/>*/}
                {/*    </IonFabButton>*/}
                {/*</IonFab>*/}
                <IonFab  vertical="bottom" horizontal="start">
                    <IonFabButton onClick={() => setChallengeBeingAdded(true)}  routerLink='/my/challenges/add/'>
                        <IonIcon icon={addIcon}/>

                        <IonIcon icon={flagOutline}/>

                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    );
};

export default MyChallengesPage;
